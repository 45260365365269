import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import 'amfe-flexible'

import '@/components/less/common.scss'
import { Field, CellGroup, Toast  } from 'vant';
import 'vant/lib/index.css';
Vue.use(Field);
Vue.use(Toast)
// 封装请求
import axios from "axios";

import qs from 'qs';
//下面是将$axios和$qs挂在原型上，以便在实例中能用 this.$axios能够拿到
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
