<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
export default {
}
</script>


<style lang="scss">
body {
  margin: 0;
  padding: 0;
  webkit-touch-callout: none;
}
</style>
