<template>
    <div class="block">
        <div class="first">参会列表</div>
        <div class="lint"></div>

        <div class="list" v-for="(item, index) in list" :key="index" @click="listClick(item)">
            <div class="list-left">
                <div class="list-name">姓名：{{ item.name }}</div>
                <div class="list-phone">电话：{{ item.phone }}</div>
                <div class="list-phone"> 时间：{{ item.createTime }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: '',
            phone: '',
            list: []
        }
    },
    created() {
        this.name = this.$route.query.name
        this.phone = this.$route.query.phone
        this.listMet()
    },
    methods: {
        listMet() {
            let url = `https://after.bainong365.cn/jeecg-boot/reservation/reservation/list?name=` + this.name + '&phone=' + this.phone;

            this.$axios.get(url)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.list = res.data.result.records
                    }
                })
        },
        listClick(item) {
            this.$router.push({
                path: '/subscribe',
                query: {
                    id: item.id
                }
            })
        }
    },
    beforeMount() {
        // 禁用双指放大
        document.documentElement.addEventListener(
            "touchstart",
            function (event) {
                if (event.touches.length > 1) {
                    event.preventDefault();
                }
            },
            {
                passive: false,
            }
        );

        // 禁用双击放大
        var lastTouchEnd = 0;
        document.documentElement.addEventListener(
            "touchend",
            function (event) {
                var now = Date.now();
                if (now - lastTouchEnd <= 300) {
                    event.preventDefault();
                }
                lastTouchEnd = now;
            },
            {
                passive: false,
            }
        );
    },
}
</script>

<style lang="less" scoped>
.block {
    .first {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #051E60;
        position: fixed;
        z-index: 10;
        background: #fff;
    }

    .lint {
        height: 50px;
        width: 100%;
        margin-bottom: 10px;
    }

    .list {
        margin: 0 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        border: 0.5px solid #ccc;
        padding: 15px 20px;
        box-sizing: border-box;

        .list-left {

            .list-name,
            .list-phone {
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #051E60;
                word-break: break-all;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .list-phone {
                margin-top: 10px;
            }
        }
    }
}
</style>