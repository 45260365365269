<template>
  <div class="home">
    <div class="home-first">
      <img class="first-back" src="../assets/back.png" alt="">

      <div class="first-block">
        <div class="title">2024</div>
        <div class="title">美国上市与并购论坛</div>
        <div class="title">参会预约</div>
        <div class="title" style="font-size: 15px"> Wall Street IPO & International M&A Forum,2024</div>
      </div>
    </div>

    <div class="home-second">
      <div class="second-block" v-if="homeShow == false">
        <div class="second-list" v-for="(item, index) in 8" :key="index">
          <div class="list-left">
            <div class="list-left-img">
              <img
                :src="index == 0 ? require('../assets/name.png') : index == 1 ? require('../assets/company.png') : index == 2 ? require('../assets/postion.png'):index == 3 ? require('../assets/mailbox.png') : index == 4 ? require('../assets/phone.png') : index == 5 ? require('../assets/wechat.png') : index == 6 ? require('../assets/industry.png') : require('../assets/num.png')"
                alt="">
            </div>
            <div class="list-left-title">{{ index == 0 ? '姓名' : index == 1 ? '公司名称' : index == 2 ? '职位' : index == 3 ?
              '邮箱'
              : index
                == 4 ? '电话' : index == 5 ? '微信' : index == 6 ? '行业':'参会人数' }}</div>
          </div>
          <div class="list-right">
            <van-field v-if="index == 0" v-model="name" placeholder="请输入内容"  />
            <van-field v-if="index == 1" v-model="companyName" placeholder="请输入内容"  />
            <van-field v-if="index == 2" v-model="postion" placeholder="请输入内容"  />
            <van-field v-if="index == 3" v-model="mail" placeholder="请输入内容"  />
            <van-field v-if="index == 4" type="digit" maxlength="11" v-model="phone" placeholder="请输入内容"  />
            <van-field v-if="index == 5" v-model="wechat" placeholder="请输入内容"  />
            <van-field v-if="index == 6" v-model="industry" placeholder="请输入内容"  />
            <van-field v-if="index == 7" type="digit" v-model="peopleNum" placeholder="请输入内容"  />
          </div>
        </div>

<!--        <div class="second-list">
          <div class="list-left">
            <div class="list-left-img">
              <img src="../assets/liuyan.png" style="object-fit: contain;" alt="">
            </div>
            <div class="list-left-title">留言</div>
          </div>
          <div class="list-right">
          </div>
        </div>

        <div class="second-message">
          <div class="message-text">
            <van-field v-model="message" rows="3" type="textarea" placeholder="说点什么吧…" show-word-limit />
          </div>
        </div>-->

        <div class="second-button" @click="addMet">
          提交
        </div>
      </div>

      <div class="second-success" v-else>
        <div class="success-img">
          <img src="../assets/success.png" alt="">
        </div>
        <div class="success-title">参会预约成功，请按时出席！</div>
        <div class="success-text">
          14:00 pm - 15:00 pm <br />
          企业家和律师交流酒会，备有美酒加美食。 <br />
          15:00 pm - 16:30 pm <br />
          美国华尔街上市及企业并购论坛。 <br />
          16:30 pm - 17:00 pm   问答时间。<br />
          17:00 pm  论坛结束<br />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      companyName: '',
      mail: '',
      phone: '',
      wechat: '',
      industry: '',
      postion: '',
      peopleNum: '',
      message: '',
      homeShow: false
    }
  },
  methods: {
    addMet() {
      let jsonData = {
        name: this.name,
        companyName: this.companyName,
        mail: this.mail,
        phone: this.phone,
        wechat: this.wechat,
        industry: this.industry,
        peopleNum: this.peopleNum,
        postion:this.postion,
        message: this.message
      };
      let url = 'https://after.bainong365.cn/jeecg-boot/reservation/reservation/add';

      this.$axios.post(url, jsonData)
        .then((res) => {
          if (res.data.code == 200) {
            this.homeShow = true
          }
        })
    }
  },
  beforeMount() {
    // 禁用双指放大
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      {
        passive: false,
      }
    );

    // 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      {
        passive: false,
      }
    );
  },
}
</script>

<style lang="less" scoped>
.home {
  .home-first {
    width: 100%;
    height: 299px;
    position: relative;

    .first-back {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
    }

    .first-block {
      position: absolute;
      top: 30px;
      left: 20px;
      z-index: 1;
      .title {
        font-size: 30px;
        font-weight: 600;
        background: linear-gradient(#ffd400, #ded8ce);
        font-family: ui-monospace;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

      }
      img {
        width: 320px;
        height: 85px;
        object-fit: cover;
      }
    }
  }

  .home-second {
    margin: 0 12px;
    margin-top: -120px;
    position: relative;
    border-radius: 8px;
    background: #fff;
    padding: 20px 20px 0;
    box-sizing: border-box;

    .second-block {
      .second-list {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: space-between;

        .list-left {
          display: flex;
          align-items: center;

          .list-left-img {
            width: 17px;
            height: 17px;
            display: flex;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }

          .list-left-title {
            width: 68px;
            margin-left: 7px;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #051E60;
          }
        }

        .list-right {
          margin-left: 7px;

          /deep/ .van-cell {
            padding: 0;
            height: 33px;
            display: flex;
            align-items: center;
            padding: 0 11px;
            background: #EBF3FF;
          }

          /deep/ .van-field__control {
            width: 180px;
          }
        }
      }

      .second-message {
        margin-bottom: 16px;

        .message-top {
          display: flex;
          align-items: center;

          .message-top-img {
            width: 17px;
            height: 17px;
            display: flex;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }

          .message-top-title {
            width: 68px;
            margin-left: 7px;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #051E60;
          }
        }

        .message-text {
          margin-top: 10px;

          /deep/ .van-cell {
            border: 1px solid #EDEDED;

          }
        }
      }

      .second-button {
        width: 100%;
        height: 41px;
        background: linear-gradient(270deg, #3D6DB5 0%, #01245F 100%);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #FFE8A9;
        margin-bottom: 60px;
      }
    }

    .second-success {
      margin-top: 45px;

      .success-img {
        width: 89px;
        height: 89px;
        margin: 0 auto;
        display: flex;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .success-title {
        font-size: 21px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #051E60;
        line-height: 29px;
        letter-spacing: 1px;
        margin: 17px 0 23px;
        text-align: left;
      }

      .success-text {
        font-size: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #051E60;
        line-height: 29px;
        letter-spacing: 1px;
        margin: 17px 0 23px;
        text-align: left;
      }


    }
  }

}
</style>
