<template>
    <div class="inquire">

        <div class="home-first">
            <img class="first-back" src="../assets/back.png" alt="">

            <div class="first-block">
                <img src="../assets/homeback1.png" alt="">
            </div>
        </div>

        <div class="inquire-second">
            <div class="second-title">下面两个输入一个查询即可</div>
            <div class="second-input">
                <van-field v-model="name" placeholder="请输入姓名" />
                <van-field type="digit" v-model="phone" placeholder="请输入手机号" maxlength="11" />
            </div>
        </div>

        <div class="inquire-button"  @click="inquireMet">
            查询</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: '',
            phone: '',
            show: false,
        }
    },
    methods: {
        inquireMet() {
            if (this.name == '' && this.phone == '') {
                this.$toast('请输入姓名或手机号！')
                return
            }

            this.subscribeMet()
        },
        subscribeMet() {
            let url = `https://after.bainong365.cn/jeecg-boot/reservation/reservation/list?name=` + this.name + '&phone=' + this.phone;

            this.$axios.get(url)
                .then((res) => {
                    if (res.data.code == 200) {
                        if (res.data.result.records.length == 0) {
                            this.$toast('暂无预约！')
                        } else if (res.data.result.records.length > 1) {
                            this.$router.push({
                                path: '/list',
                                query: {
                                    name: this.name,
                                    phone: this.phone
                                }
                            })
                        } else {
                            this.$router.push({
                                path: '/subscribe',
                                query: {
                                    id: res.data.result.records[0].id
                                }
                            })
                        }
                    }
                })
        },

    },
    beforeMount() {
        // 禁用双指放大
        document.documentElement.addEventListener(
            "touchstart",
            function (event) {
                if (event.touches.length > 1) {
                    event.preventDefault();
                }
            },
            {
                passive: false,
            }
        );

        // 禁用双击放大
        var lastTouchEnd = 0;
        document.documentElement.addEventListener(
            "touchend",
            function (event) {
                var now = Date.now();
                if (now - lastTouchEnd <= 300) {
                    event.preventDefault();
                }
                lastTouchEnd = now;
            },
            {
                passive: false,
            }
        );
    },
}
</script>

<style lang="less" scoped>
.inquire {

    .home-first {
        width: 100%;
        height: 299px;
        position: relative;

        .first-back {
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
        }

        .first-block {
            position: absolute;
            top: 67px;
            left: 52px;
            z-index: 1;

            img {
                width: 110px;
                height: 40px;
                object-fit: cover;
            }
        }
    }

    .inquire-first {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #fff;
        margin-top: -130px;
        position: relative;
        z-index: 100;
    }

    .inquire-second {
        padding: 20px 40px;

        .second-title {
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #051E60;
        }

        .second-input {
            margin-top: 20px;

            /deep/ .van-cell {
                padding: 10px 0;
                margin-bottom: 10px;
                border-bottom: 1px solid #EDEDED;
            }
        }
    }

    .inquire-button {
        margin: 0 40px;
        text-align: center;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        background: linear-gradient(270deg, #3D6DB5 0%, #01245F 100%);
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #fff;
    }

    .active {
        background: rgba(136, 175, 213, 1);
    }
}
</style>