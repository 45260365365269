import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/home.vue'
import Inquire from './views/inquire.vue'
import Subscribe from './views/subscribe.vue'
import List from './views/list.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/inquire',
      name: 'inquire',
      component: Inquire
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: Subscribe
    },
    {
      path: '/list',
      name: 'list',
      component: List
    },
  ]
})
