<template>
    <div class="subscribe">
        <div class="subscribe-first">预约信息</div>
        <div class="subscribe-lint"></div>


        <div class="subscribe-second">
            <div class="second-list" v-for="(item, index) in 8" :key="index">
                <div class="list-left">{{ index == 0 ? '姓名' : index == 1 ? '电话' : index == 2 ? '邮箱' : index == 3 ? '公司名称' :
                    index == 4
                        ? '微信' :
                        index == 5 ? '行业' : index == 6 ? '参会人数' : index == 7 ? '职位' :'' }}</div>
                <div class="list-right">{{ index == 0 ? subObj.name : index == 1 ? subObj.phone : index == 2 ? subObj.mail :
                    index == 3 ? subObj.companyName : index ==
                        4 ? subObj.wechat :
                        index == 5 ? subObj.industry : index == 6 ? subObj.peopleNum : index == 7?subObj.postion :'' }}</div>
            </div>


<!--            <div class="second-list1">
                <div class="list-left">留言</div>
                <div class="list-right">{{ subObj.message }}</div>
            </div>-->
        </div>

        <div class="subscribe-entrance" @click="subMet" :style="{ 'background': subObj.status == 0 ? '' : '#DDDDDD' }">{{
            subObj.status == false ? '入场' : '已入场' }}</div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            name: '',
            phone: '',
            subObj: {},
            id: ''
        }
    },
    created() {
        this.id = this.$route.query.id
        this.subscribeMet()

    },
    methods: {
        subscribeMet() {
            let url = `https://after.bainong365.cn/jeecg-boot/reservation/reservation/list?id=` + this.id;

            this.$axios.get(url)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.subObj = res.data.result.records[0]
                    }
                })
        },

        subMet() {
            if (this.subObj.status == 1) {
                return
            }

            let jsonData = {
                id: this.subObj.id
            };
            let url = 'https://after.bainong365.cn/jeecg-boot/reservation/reservation/admission';

            this.$axios.put(url, jsonData)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$toast.success('入场成功！')
                        this.subscribeMet()
                    }
                })
        }
    },
    beforeMount() {
        // 禁用双指放大
        document.documentElement.addEventListener(
            "touchstart",
            function (event) {
                if (event.touches.length > 1) {
                    event.preventDefault();
                }
            },
            {
                passive: false,
            }
        );

        // 禁用双击放大
        var lastTouchEnd = 0;
        document.documentElement.addEventListener(
            "touchend",
            function (event) {
                var now = Date.now();
                if (now - lastTouchEnd <= 300) {
                    event.preventDefault();
                }
                lastTouchEnd = now;
            },
            {
                passive: false,
            }
        );
    },
}
</script>

<style lang="less" scoped>
.subscribe {
    .subscribe-first {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #051E60;
        position: fixed;
        z-index: 10;
        background: #fff;
    }

    .subscribe-lint {
        height: 50px;
        width: 100%;
    }

    .subscribe-second {
        padding: 40px;
        box-sizing: border-box;

        .second-list,
        .second-list1 {
            display: flex;
            margin-bottom: 36px;
            align-items: center;
            justify-content: center;

            .list-left {
                width: 98px;
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #051E60;
            }

            .list-right {
                width: 200px;
                margin-left: 30px;
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #051E60;
                text-align: left;
            }
        }

        .second-list1 {
            align-items: baseline;
        }
    }

    .subscribe-entrance {
        width: 100px;
        height: 40px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid;
        font-size: 14px;
        color: #fff;
        background: #009688;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}
</style>